import React from 'react';

const PayPalSvg = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='100%'
			height='100%'
			x='0'
			y='0'
			viewBox='0 0 512 512'
			xmlSpace='preserve'
		>
			<g>
				<path
					d='M256 0C115.3 0 0 115.3 0 256s115.3 256 256 256 256-115.3 256-256S396.7 0 256 0z'
					fill='#cae8f9'
					data-original='#cae8f9'
				></path>
				<path
					d='M512 256c0 140.7-115.3 256-256 256V0c140.7 0 256 115.3 256 256z'
					fill='#b7e0f6'
					data-original='#b7e0f6'
				></path>
				<path
					d='M362.44 154.08 256 200.53 232 211l-47.71 150H141c-4.41 0-8.6-1.93-11.45-5.3a14.983 14.983 0 0 1-3.35-12.16l40.01-240C167.41 96.3 173.66 91 181 91h60c4.81 0 9.84-.07 15-.13 33.57-.39 72.64-.4 92.21 21.34 8.95 9.94 13.49 23.68 14.23 41.87z'
					fill='#08469c'
					data-original='#08469c'
				></path>
				<path
					d='M362.44 154.08 256 200.53V90.87c33.57-.39 72.64-.4 92.21 21.34 8.95 9.94 13.49 23.68 14.23 41.87z'
					fill='#063e8b'
					data-original='#063e8b'
				></path>
				<path
					d='M399.212 172.211c-8.954-9.946-22.053-15.24-36.773-18.127L228.856 255.106 184.29 361l-7.09 42.539a14.95 14.95 0 0 0 3.354 12.158 14.96 14.96 0 0 0 11.44 5.303h60a14.992 14.992 0 0 0 14.795-12.539l13.066-78.354c62.212-.337 123.032-5.801 132.7-91.523 3.518-31.275-.73-52.369-13.343-66.373z'
					fill='#17ace8'
					data-original='#17ace8'
				></path>
				<path
					d='M362.439 154.083 256 234.578V420.28c5.486-1.538 9.818-5.966 10.789-11.819l13.066-78.354c62.212-.337 123.032-5.801 132.7-91.523 3.516-31.274-.732-52.368-13.345-66.372-8.95-9.947-22.053-15.241-36.771-18.129z'
					fill='#1689fc'
					data-original='#1689fc'
				></path>
				<path
					d='M361.56 178.58c-8.25 73.15-53.74 87.86-105.56 90.81-8.91.51-18.01.67-27.14.72l-13.07 78.35A14.99 14.99 0 0 1 201 361h-16.71l32.92-197.46c1.2-7.24 7.45-12.54 14.79-12.54h60c21.79 0 48.04-1.31 70.44 3.08.3 7.44.14 15.43-.88 24.5z'
					fill='#0f60cf'
					data-original='#0f60cf'
				></path>
				<path
					d='M361.56 178.58c-8.25 73.15-53.74 87.86-105.56 90.81V151h36c21.79 0 48.04-1.31 70.44 3.08.3 7.44.14 15.43-.88 24.5z'
					fill='#0a4eaf'
					data-original='#0a4eaf'
				></path>
			</g>
		</svg>
	);
};

export default PayPalSvg;
