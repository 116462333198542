import { PaymentsSvg } from './Payments';
import { Completed } from './Completed';
import Delivered from './Delivered';
import Paid from './Paid';
import Pending from './Pending';
import Processing from './Processing';
import ReadyDeliver from './ReadyDeliver';
import Sending from './Sending';
import Validated from './Validated';
import Password from './Password';
import Eye from './Eye';
import EyeClose from './EyeClose';
import CartShop from './CartShop';
import CloseSessionSVG from './CloseSession';
import WishListSvg from './WishListSvg';
import RecipientsSvg from './RecipientsSvg';
import EditProfile from './EditProfile';
import UserSvg from './UserSvg';
import WholesalerSvg from './WholesalerSvg';
import FacebookSvg from './FacebookSvg';
import InstagramSvg from './InstagramSvg';
import FilterSvg from './FilterSvg';
import StarSolid from './StarSolid';
import HamburgerMenu from './HamburgerMenu';
import HomeSvg from './HomeSvg';
import CatalogSvg from './CatalogSvg';
import AdminSvg from './AdminSvg';
import StarOutlined from './StarOutlined';
import CategoriesSvg from './Categories';
import PriceSvg from './PriceSvg';
import CloseIconSvg from './Close';
import ArrowSvg from './Arrow';
import WalletSvg from './Wallet';
import MasterCard from './MasterCard';
import PayPalSvg from './PayPal';
import Visa from './Visa';

export {
	PaymentsSvg,
	Completed,
	Delivered,
	Paid,
	Pending,
	Processing,
	ReadyDeliver,
	Sending,
	Validated,
	Password,
	Eye,
	EyeClose,
	CartShop,
	CloseSessionSVG,
	WishListSvg,
	RecipientsSvg,
	EditProfile,
	UserSvg,
	WholesalerSvg,
	FacebookSvg,
	InstagramSvg,
	FilterSvg,
	StarSolid,
	HamburgerMenu,
	HomeSvg,
	CatalogSvg,
	AdminSvg,
	StarOutlined,
	CategoriesSvg,
	PriceSvg,
	CloseIconSvg,
	ArrowSvg,
	WalletSvg,
	MasterCard,
	PayPalSvg,
	Visa,
};
