import React from 'react';

const WalletSvg = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			version='1.1'
			id='Capa_1'
			x='0px'
			y='0px'
			viewBox='0 0 144.3 144.3'
			xmlSpace='preserve'
		>
			<g>
				<circle style={{ fill: '#3C80AD' }} cx='72.1' cy='72.1' r='72.1' />
				<path
					style={{ fill: '#073854' }}
					d='M72,113c-10.1,0-20.2,0-30.2,0c-5.4,0-9.5-3.3-10.5-8.5c-0.1-0.7-0.1-1.4-0.1-2.1c0-18.9,0-37.7,0-56.6   c0-7.5,4.9-13.2,12.2-14.5c0.8-0.1,1.6-0.1,2.4-0.1c14.8,0,29.6,0,44.3,0c5.4,0,9.5,3.3,10.5,8.5c0.1,0.7,0.1,1.5,0.1,2.3   c0,4.3,0,8.5,0,12.8c0,0.9,0.3,1.1,1.1,1c3.8-0.2,7,1.1,9.3,4.2c1.2,1.6,1.9,3.5,1.9,5.5c0,7.4,0,14.8,0,22.2c0,5,0,10,0,15   c0,5.3-3.4,9.4-8.5,10.3c-0.8,0.1-1.6,0.1-2.4,0.1C92,113,82,113,72,113z M70.5,55.7c8.4,0,16.8,0,25.1,0c0.9,0,1-0.2,1-1.1   c0-4.3,0-8.6,0-12.9c0-4-2.6-6.6-6.6-6.6c-4.8,0-9.6,0-14.4,0c-10.1,0-20.1,0-30.2,0c-7.2,0-12.1,7.1-9.6,13.8   c1.5,4.1,5.3,6.7,10,6.7C54.1,55.7,62.3,55.7,70.5,55.7z M70,67.9C70,67.9,70,67.9,70,67.9c0,0.3,0,0.6,0,0.9   c0.2,1.1-0.2,1.6-1.3,2.1c-3,1.2-4.9,4.4-4.8,7.6c0.1,3.4,2.2,6.3,5.4,7.5c1,0.4,2.1,0.5,3.2,0.5c2.1,0.2,3.6,2,3.6,4.1   c0,2.1-1.7,3.9-3.8,4c-2.4,0.1-4.1-1.4-4.4-3.9c-0.1-1.4-1-2.3-2.2-2.2c-1.3,0.1-2,1.2-1.9,2.6c0.3,2.7,1.5,4.8,3.8,6.4   c0.7,0.5,2,0.4,2.3,1.3c0.3,0.9,0.1,1.9,0.1,2.9c0,0.3,0,0.6,0,0.9c0,1.4,0.9,2.4,2.1,2.4c1.2,0,2-0.9,2-2.3c0-1.1,0-2.2,0-3.3   c0-0.6,0.1-0.9,0.7-1.1c3.9-1.4,6.1-5.5,5.1-9.6c-0.9-3.7-3.8-6-8-6.3c-2.3-0.2-4-1.8-3.9-4.1c0-2.2,1.7-3.9,3.8-4   c2.3-0.1,4.1,1.5,4.3,3.8c0.1,1.5,1,2.4,2.3,2.3c1.3-0.1,2-1.2,1.8-2.8c-0.4-3.4-2.2-5.8-5.5-7c-0.5-0.2-0.6-0.4-0.6-0.9   c0-1.2,0-2.4,0-3.6c0-1.2-0.9-2.1-2-2.1c-1.1,0-2,0.9-2.1,2.1C70,66.6,70,67.2,70,67.9z'
				/>
				<path
					style={{ fill: '#FCFCFC' }}
					d='M70.5,55.7c-8.2,0-16.4,0-24.6,0c-4.7,0-8.5-2.6-10-6.7c-2.5-6.7,2.4-13.8,9.6-13.8c10.1,0,20.1,0,30.2,0   c4.8,0,9.6,0,14.4,0c4,0,6.6,2.6,6.6,6.6c0,4.3,0,8.6,0,12.9c0,0.8-0.1,1.1-1,1.1C87.2,55.7,78.9,55.7,70.5,55.7z M66,43.4   c-6.6,0-13.2,0-19.8,0c-0.4,0-0.7,0-1.1,0c-1,0.1-1.7,0.9-1.8,1.9c0,1.3,0.9,2.1,2.5,2.1c13.3,0,26.7,0,40,0c0.2,0,0.4,0,0.7,0   c1.1-0.1,1.8-1,1.8-2.1c0-1.2-0.9-2-2.3-2C79.4,43.4,72.7,43.4,66,43.4z'
				/>
				<path
					style={{ fill: '#FAFAFA' }}
					d='M70,67.9c0-0.6,0-1.3,0-1.9c0.1-1.2,0.9-2.1,2.1-2.1c1.1,0,2,0.9,2,2.1c0,1.2,0,2.4,0,3.6   c0,0.5,0.2,0.7,0.6,0.9c3.2,1.2,5,3.6,5.5,7c0.2,1.5-0.5,2.6-1.8,2.8c-1.2,0.1-2.1-0.8-2.3-2.3c-0.2-2.4-2-3.9-4.3-3.8   c-2.1,0.1-3.8,1.9-3.8,4c0,2.3,1.6,3.9,3.9,4.1c4.2,0.3,7.1,2.6,8,6.3c1,4-1.2,8.2-5.1,9.6c-0.6,0.2-0.8,0.5-0.7,1.1   c0,1.1,0,2.2,0,3.3c0,1.4-0.8,2.3-2,2.3c-1.2,0-2.1-0.9-2.1-2.4c0-0.3,0-0.6,0-0.9c0-1,0.2-2-0.1-2.9c-0.3-0.9-1.5-0.8-2.3-1.3   c-2.3-1.5-3.5-3.6-3.8-6.4c-0.1-1.5,0.6-2.5,1.9-2.6c1.2-0.1,2.1,0.8,2.2,2.2c0.2,2.4,2,4,4.4,3.9c2.1-0.1,3.7-1.9,3.8-4   c0-2.1-1.5-3.9-3.6-4.1c-1.1-0.1-2.2-0.2-3.2-0.5c-3.2-1.1-5.3-4.1-5.4-7.5c-0.1-3.3,1.8-6.4,4.8-7.6c1.1-0.4,1.5-1,1.3-2.1   C69.9,68.5,70,68.2,70,67.9C70,67.9,70,67.9,70,67.9z'
				/>
				<path
					style={{ fill: '#073854' }}
					d='M66,43.4c6.7,0,13.4,0,20.1,0c1.5,0,2.3,0.7,2.3,2c0,1.1-0.7,2-1.8,2.1c-0.2,0-0.4,0-0.7,0   c-13.3,0-26.7,0-40,0c-1.6,0-2.5-0.8-2.5-2.1c0-1,0.7-1.8,1.8-1.9c0.3,0,0.7,0,1.1,0C52.8,43.4,59.4,43.4,66,43.4z'
				/>
			</g>
		</svg>
	);
};

export default WalletSvg;
