import React from 'react';

type Props = { color?: string };

const Visa = ({ color = 'white' }: Props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='100%'
			height='100%'
			x='0'
			y='0'
			viewBox='0 0 512 512'
			xmlSpace='preserve'
		>
			<g>
				<path
					fill='#f3f2f2'
					d='M7.9 256C7.9 119 119 7.9 256 7.9S504.1 119 504.1 256 393 504.1 256 504.1 7.9 393 7.9 256z'
					opacity='1'
					data-original='#f3f2f2'
				></path>
				<path
					fill='#293688'
					d='m191.6 303.6 19-112.1h30.8l-19 112.1zM331.8 194.4c-6.1-2.3-15.6-4.7-27.5-4.7-30.4 0-51.8 15.2-52 37-.2 16.1 15.3 25.1 26.9 30.5 12 5.5 16 9 15.9 13.9-.1 7.5-9.6 10.9-18.4 10.9-12.3 0-18.8-1.7-28.9-5.9l-4-1.8-4.3 25.1c7.2 3.1 20.5 5.8 34.2 6 32.3 0 53.3-15 53.5-38.3.1-12.7-8.1-22.5-25.8-30.5-10.7-5.2-17.3-8.6-17.3-13.9 0-4.7 5.6-9.6 17.6-9.6 10.1-.2 17.3 2 23 4.3l2.8 1.3zM384.2 191.5c-7.4 0-12.7 2.2-15.9 9.5l-45.5 102.5h32.5l6.3-17.2h38.9l3.6 17.2H433l-25.3-112.1h-23.5zm-14 72.5c2.5-6.5 12.2-31.3 12.2-31.3-.2.3 2.5-6.5 4.1-10.7l2.1 9.7s5.9 26.8 7.1 32.4h-25.5zM135.6 268.3l-2.7-15.4c-5.6-17.9-23-37.3-42.5-47l27.2 97.6h32.5l48.8-112.1h-32.5z'
					opacity='1'
					data-original='#293688'
				></path>
				<path
					fill='#f7981d'
					d='M56.9 191.5v1.8c38.6 9.3 65.3 32.7 75.9 59.7l-11.1-51.9c-1.9-7.1-7.5-9.3-14.2-9.6zM256 504.1c95.8 0 178.8-54.3 220.1-133.7H35.9C77.2 449.8 160.3 504.1 256 504.1z'
					opacity='1'
					data-original='#f7981d'
				></path>
				<path
					fill='#293688'
					d='M256 7.9c-94.2 0-176.2 52.5-218.2 129.9h436.4C432.2 60.5 350.2 7.9 256 7.9z'
					opacity='1'
					data-original='#293688'
				></path>
			</g>
		</svg>
	);
};

export default Visa;
